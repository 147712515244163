import React from "react"
import "@exampledev/new.css/new.css";
import "@exampledev/new.css/theme/night.css";
import Header from "../Header"
import foodbankmapimg from "../images/foodbankmap.png"
import naicsgraphsimg from "../images/naicsgraphs.png"
import migrationimg from "../images/migration.png"
import covid19img from "../images/covid19.png"


function Entry(props){
    let codetext = "";
    if(props.code !== undefined){
        codetext = <span>| <a href={props.code}>Code</a></span>
    }
    return(
        <table>
                <caption>{props.caption}</caption>
                <colgroup>
                    <col span="1" style={{width: "50%"}}/>
                    <col span="1" style={{width: "50%"}}/>
                </colgroup>
                <tbody>
                    <tr>
                        <td>
                            <a href={props.img}><img style={{"margin-bottom": "0px"}} src={props.img}></img></a>
                        </td>
                        <td>
                            {props.desc}
                            <br/>
                            <a href={props.link}>Website</a> {codetext}
                        </td>
                    </tr>
                </tbody>
        </table>
    )
}


export default function Visualizations() {
    return (
        <>
            <Header title="Visualizations"></Header>
            <p>Some of my favourite visualizations that I've built. You can find many more on my <a href="https://observablehq.com/@johnhaldeman">Observable</a> page.</p>
            <Entry caption="Windsor-Essex Walk Times to Food Banks Open During COVID-19 Outbreak" img={foodbankmapimg}
                link="https://observablehq.com/@johnhaldeman/windsor-walk-times-to-food-banks-open-during-covid-19-outbre"
                desc="A notebook showing walk times from every intersection and census tract median income in Windsor-Essex to the nearest 
                    food bank open during the COVID-19 outbreak. Uses DeckGL. Intersections from Open Street Maps' Overpass API.">
            </Entry>
            <br/>
            <Entry caption="Employment in Industry for Statistics Canada Census Metropolitan Areas (CMAs)" img={naicsgraphsimg} 
                link="https://observablehq.com/@johnhaldeman/employment-in-industry-for-statistics-canada-census-metro"
                desc="A notebook showing industry employment for a selectable CMA in Canada in two ways - a bar chart and line chart. The bar
                    chart and line chart mirror eachother as time progresses or interacted with by the user. Built with D3.">
            </Entry>
            <br/>
            <Entry caption="Migration to/from Canadian CMAs/CAs for 2016/17" img={migrationimg}
                link="https://observablehq.com/@johnhaldeman/migration-to-from-canadian-cmas-cas-for-2016-17"
                desc="A notebook cross CMA migrations for Canadian cities. It is interaction and shows the migration numbers in two ways, a map and 
                    pyramid chart. When you change the CMA/CA by clicking on the map the chart and the map track eacother. Built with DeckGL and D3.">
            </Entry>
            <br/>
            <Entry caption="COVID-19 Testing and Hosptilization: Ontario, Canada" img={covid19img}
                link="https://observablehq.com/@johnhaldeman/migration-to-from-canadian-cmas-cas-for-2016-17"
                desc="A notebook showing various statistics on COVID-19 testing and hospitalization in Ontario. All graphs shown and built with
                    Vega Lite.">
            </Entry>
            <br/>
            
        </>
    )

}